import Cookies from 'universal-cookie';
import {  DESKTOP, TABLET, MOBILE, MEDIUM, SMALL } from '@sgp/data/constants.js';
import { getEquoteUrl } from '@sgp/lib/util/env-urls';

export const currencyConversion = (digits) => {
  if (!digits) return '0.00';
  return `${parseFloat(digits)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const getYesNoCodeValue = (code) => {
  if (!code) return 'No';
  const yesNoCodes = [
    { code: 'Y', value: 'Yes' },
    { code: 'N', value: 'No' },
  ];
  return yesNoCodes.filter((obj) => obj?.code === code)[0]?.value;
};

export const codeWiseObjSelection = (objs, code) => {
  return objs.filter((obj) => obj?.addressRelationshipCode === code);
};


export const formatPhone = (contact) => {
  let countryCode = contact[0]?.countryCode ? contact[0]?.countryCode + ' - ' : '';
  let areaCode = contact[0]?.areaCode ? contact[0]?.areaCode + ' - ' : '';
  let phoneNumber = contact[0]?.phoneNumber ? contact[0]?.phoneNumber : '';
  return `${countryCode}${areaCode}${phoneNumber}`;
};

export const formatPolicyOwnerAndInsurance = (data) => {
  let policyData = [];
  data.map((val, i) => {
    let name = `${val?.formatName.trim()}`;
    policyData.push(name.trim());
  });
  return policyData;
};

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

//For base64Encoding
export const DownloadEncodedDocs = ((name, extensionType, content) => {
  let fileExtensionType;
  switch (extensionType) {
  case 'pdf':
    fileExtensionType = 'application/pdf';
    break;
  case 'docx':
    fileExtensionType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    break;
  case 'doc':
    fileExtensionType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    break;
  case 'xlsx':
    fileExtensionType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    break;
  case 'xls':
    fileExtensionType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    break;
  case 'ppt':
  case 'pptx':
    fileExtensionType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    break;
  case 'zip':
    fileExtensionType = 'application/zip';
    break;
  case 'txt':
    fileExtensionType = '@file/plain';
    break;
  case 'svg':
    fileExtensionType = 'image/svg+xml';
    break;
  case 'png':
    fileExtensionType = 'image/png';
    break;
  case 'jpg':
  case 'jpeg':
    fileExtensionType = 'image/jpeg';
    break;
  case 'gif':
    fileExtensionType = 'image/gif';
    break;
  case 'ico':
  case 'icon':
    fileExtensionType = 'image/x-icon';
    break;
    case 'tif':
    case 'tiff':
    fileExtensionType = 'image/tiff';
    break;
  default:
    fileExtensionType = 'application/pdf';
    break;
  }
  const blob = b64toBlob(content, fileExtensionType);
  if ( Object.prototype.toString.call(blob) === '[object Blob]' && navigator.msSaveBlob) { 
    let fname = name ? name : 'download';
    console.log("filename in IE:" , `${fname}.${extensionType}`);
    window.navigator.msSaveBlob(blob, `${fname}.${extensionType}`);

  } else {
    // ip(hone||ad) safari browser pdf fix
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    if ((/ip(hone|ad)/i.test(navigator?.userAgent?.toLowerCase()) && (navigator?.userAgent?.indexOf("Safari") > -1)) && extensionType === 'pdf') {
      window.open(blobUrl, '_blank');
    }
    else{
      const linkSource = `data:${fileExtensionType};base64,${content}`;
      downloadLink.href = linkSource;    
      downloadLink.target = '_blank';
      downloadLink.rel = 'noreferrer noopener';
      downloadLink.download = name ? name : 'download';
      downloadLink.click();
    }
  }
});

export const verifyDocumentContent = (doc = null) => {
  if (!doc?.link && doc?.content) {
    return 'popup';
  }
  if(!doc?.link) return false;
  const ext = getUrlExtension(doc?.link);
  if (
    ['jpg', 'jpeg', 'png', 'svg', 'gif', 'ico', 'icon', 'tif', 'tiff', 'doc', 'docx', 'xls', 'xlsx', 'pdf', 'txt', 'ppt', 'pptx'].indexOf(
      ext,
    ) >= 0
  )
    return 'download';
  return 'newtab';
};

export const getUrlExtension = (url) => {
  return url?.split(/[#?]/)[0].split('.').pop().trim();
};

export const sessionClear = () => {
  // Remove all the user-session related data except Start Time
  // Add End Time
  const cookies = new Cookies();
  cookies.remove('dspSessionDup');
  localStorage.removeItem('selected-app');
  localStorage.removeItem('idleWarnCounter');
  localStorage.removeItem('advisor');
  sessionStorage.removeItem('agentBasicInfo');
  sessionStorage.removeItem('headerType');
  sessionStorage.removeItem('showWarnMessage');
  localStorage.setItem('endTime', new Date().getTime())
};

export const redirectEquoteUrl = (pageId) => {
  // const link = `${getEquoteUrl()}?authCode=${authCode}&uniqueId=${id}`;

  const link = getEquoteUrl();

  if(pageId && pageId === 'leftnav') {
    window.open(link, '_blank');
  }

  if(pageId && pageId === 'authorize') {
    window.location.replace(link);
  }
}

//Filter out cols based passing index for mobile view display
export const removeCols = (arrs, removedIndexs) => {
  return arrs.filter((arr, index) => (removedIndexs.indexOf(index) === -1));
};

//Device name settings based on device size
export const deviceDetection = (deviceWidth) => {
  if (SMALL < deviceWidth && deviceWidth <= MEDIUM) //600< deviceWidth <= 959
    return TABLET;
  else if (deviceWidth <= SMALL) // deviceWidth <= 600
    return MOBILE;
  else
    return DESKTOP; // deviceWidth >=960
};

export const getPolicyStatusText = (policyStatus) =>{
   return policyStatus ? policyStatus.toUpperCase().replace(/ /g, "_") : null
}

// converts html string to string
export const createMarkup = (htmlContent) => {
  return { __html: htmlContent };
};
