// third party libs
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

//piggybank
import Paragraph from '@piggybank/core/components/Paragraph';
import { Grid, Row, Cell } from '@piggybank/core/components/Grid';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

//styles and NLS
import styles from './styles.pcss';
import nls from './nls/strings_nls';
//import Link from '@piggybank/core/components/Link'; // not using Link component as with extrenal link its default attaching icon and as mockup we are not require that

const Footer = memo(({
  NLS,
  textMap = { footer: NLS`footer` },
  dsptoken = null,
}) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <>
        <Grid>
          <Row>
            <Cell>
              <div className={cn(styles.JustifyContentWrapper)}>
                <ul className={dsptoken ? styles.LinkDivAlignment : ''}>
                  <li><a href="https://www.insurance.hsbc.com.sg/hyperlink-policy/"  rel="noreferrer"  target="_blank">{NLS`link1`}</a></li>
                  <li><a href="https://www.insurance.hsbc.com.sg/content/dam/hsbc/insn/documents/data-privacy-policy.pdf" rel="noreferrer" target="_blank">{NLS`link2`}</a></li>
                  <li><a href="https://www.insurance.hsbc.com.sg/terms-of-use/"  rel="noreferrer" target="_blank">{NLS`link3`}</a></li>
                </ul>
                <ul className={cn({ [styles['CopyRightParagraph']]: !dsptoken })}>
                  <li><Paragraph marginBottom={0}>&copy;{textMap.footer}{currentYear}</Paragraph></li>
                </ul>
              </div>
            </Cell>
          </Row>
        </Grid>
      </>
    </footer>
  );
});

Footer.propTypes = {
  textMap: PropTypes.shape({
    footer: PropTypes.node,
  }),
  dsptoken: PropTypes.any,
};

export default withNLS(nls)(Footer);
