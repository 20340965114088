// third party libs
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//piggybank
import Paragraph from '@piggybank/core/components/Paragraph';
import ModalDialog from '@piggybank/core/components/ModalDialog';
import Button from '@piggybank/core/components/Button';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

// local components and data
import NotificationErrorFilled from '@sgp-common/components/Page/Icon/icons/NotificationErrorFilled.js';

//styles and NLS
import nls from '@sgp/HOC/withUserSessionMonitor/nls/strings_nls';
import styles from '@sgp/HOC/withUserSessionMonitor/styles.pcss';

const Logout = React.memo(({ NLS, onLogin, logoutReason }) => {
    
    return (
        <ModalDialog
        name="sessionTimeout"
        show={true}
        dismissible={false}
        alert={true}
        title=""
        >
            <div className={styles.WarningModalContent}>
                <NotificationErrorFilled width='32px' height='32px'/>
                <h2>{NLS`sessionTimeoutTitle`}</h2>
                <Paragraph marginBottom={6}>
                {logoutReason && logoutReason === 'idle' ?  NLS`idleTimeoutText` : NLS`sessionTimeoutText`}
                </Paragraph>
                <div className={styles.btnWrapper}>          
                <div>
                    <Button type="button" onClick={() => onLogin()}>{NLS`Login`}</Button>
                </div>
                </div>
            </div>
        </ModalDialog>
    );
});

Logout.propTypes = {
  NLS: PropTypes.func,
  onLogin: PropTypes.func,
  logoutReason: PropTypes.string.isRequired
};

export default withNLS(nls)(Logout)