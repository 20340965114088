// third party libs
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//piggybank
import Paragraph from '@piggybank/core/components/Paragraph';
import ModalDialog from '@piggybank/core/components/ModalDialog';
import Button from '@piggybank/core/components/Button';
import HorizontalRule from '@piggybank/core/components/HorizontalRule';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

// local components and data
import NotificationErrorFilled from '@sgp-common/components/Page/Icon/icons/NotificationErrorFilled.js';
import { IDLE_LOGOUT_TIMEOUT } from '@sgp/data/constants';

//styles and NLS
import nls from '@sgp/HOC/withUserSessionMonitor/nls/strings_nls';
import styles from '@sgp/HOC/withUserSessionMonitor/styles.pcss';

const Warning = React.memo(({ onHideWarnMessage, onLogout, NLS }) => {
  const [timeDifference, setTimeDifference] = useState( 1000 * 60 * IDLE_LOGOUT_TIMEOUT );
  
  useEffect(() => {
    const timeInterval = setInterval(() => {
                          !timeDifference && clearInterval(timeInterval)
                          setTimeDifference(prevtimeDifference => prevtimeDifference - 1000);
                        }, 1000)
    return () => clearInterval(timeInterval)
  }, [])

  const remainingTime = () => {
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return `${minutes > 0 ? minutes + ' min(s)' : ''} ${seconds > 0 ? seconds + ' sec(s)' : ''}`;
  };

  return (
    <ModalDialog
      name="needMoreTime"
      show={true}
      dismissible={false}
      alert={true}
      title=""
    >
      <div className={styles.WarningModalContent}>
        <NotificationErrorFilled width='32px' height='32px'/>
        <h2>{NLS`wannaContinueTitle`}</h2>
        <Paragraph marginBottom={2}>
          {NLS`wannaContinueHeader`} <strong>{remainingTime()}</strong>.
        </Paragraph>
        <Paragraph marginBottom={6}>
          {NLS`wannaContinueText`}
        </Paragraph>
        <HorizontalRule hiddenOnSmallViewport />
        
        <div className={styles.btnWrapper}>
          <div>
            <Button type="button" onClick={() => onHideWarnMessage()}>{NLS`Continue`}</Button>
          </div>
          <div>
            <Button type="button" secondary onClick={() => onLogout('manual')}>{NLS`Logout`}</Button>
          </div>
        </div>
      </div>
    </ModalDialog>
  );
});

Warning.propTypes = {
  onHideWarnMessage: PropTypes.func,
  onLogout: PropTypes.func,
  NLS: PropTypes.func
};

export default withNLS(nls)(Warning)