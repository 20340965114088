/* istanbul ignore file */
// third party libs
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/fp/pick';
import isNull from 'lodash/fp/isNull';
import cn from 'classnames';

// piggybank
import {
  IconFilterActive,
  IconClose,
  IconSearch,
  getMarginBottomClass,
  marginBottomLevels,
  withContext,
  eventBus,
} from '@piggybank/core';
import { Consumer } from '@piggybank/form/components/Field/context';

// mws common-ui
import Text, { TEXT_REGULAR } from '@shared/components/Text';
import withNLS from '@shared/HOC/withNLS';

// nls and styles
import styles from './style.pcss';
import nls from './nls/strings_nls';

const STRING = 'string';

const Select = React.forwardRef(
  (
    {
      NLS,
      className,
      deselectableText,
      fullWidth,
      invalid,
      marginBottom,
      name,
      options,
      placeholder,
      value,
      required,
      describers,
      deselectable,
      readonly,
      onChange,
      disabledValues,
      optionValue,
      optionLabel,
      searchValue,
      onClose,
      ...other
    },
    ref
  ) => {
    const [showPopup, setShowPopup] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [placeHolderValue, setPlaceHolderValue] = useState('1_POLICYOWNER');
    const inputRef = useRef(null);
    const searchRef = useRef(null);
    const filterRef = useRef(null);
    const optionsRef = useRef(null);
    useEffect(() => {
      if (!showPopup) {
        inputRef.current.blur();
      }
    }, [showPopup]);

    useEffect(() => {
      document.addEventListener('click', handleSearchOutSideClick);
      return () => {
        document.removeEventListener('click', handleSearchOutSideClick);
      };
    }, [showPopup]);

    const handleSearchOutSideClick = e => {
      if(showPopup && isNull(optionsRef?.current)) return;

      if ((inputRef?.current?.contains(e.target)) ||
        (optionsRef?.current?.contains(e.target)) ||
        (filterRef?.current?.contains(e.target)) ||
        (searchRef?.current?.contains(e.target)) 
      ) 
        return;
      else {        
          setclosed();
      }
    };

    const onChangeEvnt = useCallback((currentVal, event) => {
      if (currentVal.value === '1_POLICYOWNER' || currentVal.value === '2_LIFEINSURED' || currentVal.value === '3_DOCUMENTID' || currentVal.value === '4_POLICYID' || currentVal.value === '5_PRODUCTNAME') {
        setPlaceHolderValue(currentVal.value);
      } else {
        setInputValue(currentVal.value);
        onChange(currentVal.value, event);
      }

    }, []);

    const getPlaceHoldervalue = useMemo(() => {
      const option = options.find((v) => v.value === placeHolderValue);
      return option ? option.label : '';
    }, [placeHolderValue, options]);

    const onSelect = (event, option) => {
      const currentValue = option.value;
      if (isDisabled(currentValue)) {
        event.stopPropagation();
        return;
      }
      setShowPopup(false);
      onChangeEvnt({ value: currentValue, event });
      eventBus.dispatch({
        type: 'onChange',
        component: 'Select',
        value: currentValue,
        name,
      });
    };

    const isString = (val) => {
      return typeof val === STRING;
    };

    const isSelected = (val) => {
      return val === value;
    };

    const isDisabled = (val) => {
      return disabledValues.includes(val);
    };

    const currentOptions = useMemo(
      () =>
        options.map((option) => ({
          value: isString(optionValue)
            ? option[optionValue]
            : optionValue(option),
          label: isString(optionLabel)
            ? option[optionLabel]
            : optionLabel(option),
        })),
      [options]
    );

    const onDeselectClick = (e) => {
      onSelect(e, { value: '' });
    };

    const onPopupChange = useCallback((show) => {
      if (!show) inputRef.current.focus();
      setShowPopup(show);
    }, []);

    const setFinalValue = useCallback(() => {
      if (inputValue) {
        searchValue({ value: [placeHolderValue, inputValue], event });
        eventBus.dispatch({
          type: 'onClick',
          component: 'Select',
          value: [placeHolderValue, inputValue],
          name,
        });
        setclosed();
      }
    }, [placeHolderValue, inputValue]);

    const setclosed = useCallback(() => {      
      onClose({ value: 'close', event });
      eventBus.dispatch({
        type: 'onClick',
        component: 'Select',
        value: 'close',
        name,
      });
    }, []);

    //32 space
    //13 enter
    const onEnterKeyPress = useCallback((event) => {
      var code = (event.which) ? event.which : event.keyCode;
      
      if(inputValue && code === 13) { 
        searchValue({ value: [placeHolderValue, inputValue], event });
       }

      // if (code === 13 || code === 32 || (code > 47 && code < 58) || (code > 64 && code < 91) || (code > 96 && code < 123)){
      //     if(inputValue && code === 13) { 
      //      searchValue({ value: [placeHolderValue, inputValue], event });
      //     }
      // } else {
      //   event.preventDefault();
      //    return false;
      // }

    }, [placeHolderValue, inputValue]);

    const onIconClick = useCallback((e) => {
      if (!showPopup) {
        onPopupChange(true);
      } else {
        onPopupChange(false);
      }
    }, [showPopup]);

    return (
      <div
        ref={ref}
        className={cn(
          styles['select'],
          {
            [styles['full-width']]: fullWidth,
            [className]: className,
          },
          getMarginBottomClass(marginBottom)
        )}
        {...other}
      >
        <input
          ref={inputRef}
          aria-invalid={invalid}
          aria-describedby={describers}
          type="text"
          className={cn(styles.input, { [styles['invalid']]: invalid })}
          //readOnly={readonly}
          value={inputValue}
          //   placeholder={placeholder}
          placeholder={(getPlaceHoldervalue) ? `Search ${getPlaceHoldervalue}` : `${placeholder}`}
          name={name}
          required={required}
          title={inputValue}
          onKeyPress={onEnterKeyPress}
          onChange={(e) => { setInputValue(e.target.value); }}
        />

        <span className={cn(showPopup ? styles.active : '', styles.chevronDown)} ref={filterRef} tabIndex="0">
          <IconFilterActive marginBottom={0} onClick={onIconClick} />
        </span>

        <span className={styles.search} onClick={() => { setFinalValue(); }} ref={searchRef} tabIndex="0">
          <IconSearch marginBottom={0} />
        </span>
        <span className={styles.close} onClick={() => { setclosed(); }} tabIndex="0">
          <IconClose marginBottom={0} />
        </span>

        {showPopup &&
          <div
            className={cn(styles.dropDown)}
            style={{ maxHeight: '240px' }}
            ref={optionsRef}
          >

            {deselectable && (
              <div className={styles.option} onClick={onDeselectClick}>
                <Text weight={TEXT_REGULAR}>
                  {deselectableText || NLS`showAll`}
                </Text>
              </div>
            )}

            {currentOptions.map((o) => (
              <div
                key={o.value}
                className={`${styles.option} ${
                  isSelected(o.value) ? styles.selected : ''
                }`}
                onClick={(e) => onSelect(e, o)}
              >
                <Text
                  weight={TEXT_REGULAR}
                  className={isDisabled(o.value) ? styles.disabled : ''}
                >
                  {o.label}
                </Text>
              </div>
            ))}

          </div>
        }
      </div>

    );
  }
);

Select.displayName = 'Select';

Select.propTypes = {
  deselectableText: PropTypes.string,
  fullWidth: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  invalid: PropTypes.bool,
  /**
   * 0, 1, 2, 3, 4, 5, 6, 7
   */
  marginBottom: PropTypes.oneOf(marginBottomLevels),
  /** docgen-from-context:<Field/> */
  name: PropTypes.string.isRequired,
  /**
   * docgen-from-context:<Field/>
   * ---
   * proxied to eventBus
   */
  onChange: PropTypes.func.isRequired,
  /** docgen-from-context:<Field/> */
  onBlur: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  /** docgen-from-context:<Field/> */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** docgen-from-context:<Field/> */
  required: PropTypes.bool,
  /** docgen-from-context:<Field/> */
  describers: PropTypes.string,
  deselectable: PropTypes.bool,
  readonly: PropTypes.bool,
  disabledValues: PropTypes.array,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Select.defaultProps = {
  marginBottom: 0,
  placeholder: 'Search Policy owner',
  readonly: true,
  disabledValues: [],
  fullWidth: false,
  optionValue: 'value',
  optionLabel: 'label',
  invalid: false,
  required: false,
};

export { Select };

export default withContext(
  Consumer,
  pick([
    'name',
    'value',
    'onChange',
    'onBlur',
    'invalid',
    'required',
    'describers',
  ])
)(withNLS(nls)(Select));
