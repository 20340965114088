import React from 'react';
import cn from 'classnames';
const IconDirectionUp = props => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 14 7" className={cn('pb-core-components-Icon-icon--Icon', 'pb-core-components-Icon-icon--Icon-up')} role="img" focusable="false" aria-hidden="true">
    <defs>
      <polygon id="path-1" points="0 0 14 0 7 7"></polygon>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="header" transform="translate(-585.000000, -20.000000)">
        <g id="Table">
          <g id="Table-header-">
            <g>
              <g>
                <g id="Customer-table-row-">
                  <g>
                    <g>
                      <g id="Tables-Display/Size:S6/Header-Example/RScale:2">
                        <g id="System-icons/-Filter-down-/" transform="translate(592.000000, 23.500000) scale(1, -1) translate(-592.000000, -23.500000) translate(585.000000, 20.000000)">
                          <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1"></use>
                          </mask>
                          <use id="New" fill="#767676" fillRule="evenodd" xlinkHref="#path-1"></use>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(IconDirectionUp);
