import React from 'react';
const ContactFilled = props => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M23.48,17.7426667 L19.8453333,14.108 C19.1746667,13.436 18.0026667,13.4373333 17.3333333,14.1066667 L15.3573333,16.0826667 C15.296,16.144 15.1973333,16.1546667 15.1346667,16.1053333 C14.1613333,15.356 13.2293333,14.5346667 12.364,13.6706667 L10.3306667,11.6373333 C9.46,10.7653333 8.64,9.83333333 7.89333333,8.86533333 C7.84533333,8.80266667 7.856,8.704 7.91733333,8.64266667 L9.89333333,6.66666667 C10.5853333,5.97466667 10.5853333,4.848 9.89333333,4.15466667 L6.25866667,0.521333333 C5.588,-0.150666667 4.41733333,-0.150666667 3.74533333,0.521333333 L1.088,3.18 C-0.0293333333,4.29733333 -0.316,6.01733333 0.374666667,7.46 C2.88,12.692 7.104,16.6026667 7.23466667,16.7186667 C7.39866667,16.8973333 11.3093333,21.1186667 16.54,23.6253333 C17.064,23.876 17.6253333,23.9986667 18.18,23.9986667 C19.152,23.9986667 20.108,23.624 20.82,22.912 L23.4773333,20.2546667 C23.8146667,19.9186667 24,19.4733333 24,19 C24,18.5253333 23.816,18.0786667 23.48,17.7426667 Z" id="ContactFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="24" height="24"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#ContactFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#ContactFilled"></use>
      </g>
    </g>
  </svg>
);

export default React.memo(ContactFilled);
