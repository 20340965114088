// third party libs
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';

//piggybank
import { Grid, Row, Cell } from '@piggybank/core/components/Grid';
import Page from '@piggybank/core/components/Page';

// local components
import Left from '@sgp-common/components/Left';
import Header from '@sgp-common/components/Header';
import Footer from '@sgp-common/components/Footer';
import styles from './styles.pcss';
import { useDspContext } from '@sgp/views/Context';
import Routes from './Routing';
import LoginChoiceBgImage from '@sgp/assets/loginChoiceBgImage.jpg';

const App = (...props) => {
  const cookie = new Cookies();
  let { dsptoken } = useDspContext();
  dsptoken = cookie.get('dspSessionDup') ||  dsptoken.value;

  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = useCallback((val) => { setSidebar(val); }, []);
  
  let ImageStyle = {
    backgroundImage: `url(${LoginChoiceBgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  
  return (
    <div style={!dsptoken ? ImageStyle : {}} >
      <Router basename="/">
        <Switch>
          <Page
            headerSlot={<Header sidebar={sidebar} toggleSidebar={toggleSidebar} dsptoken={dsptoken} />}
            footerSlot={<Footer dsptoken={dsptoken} />}>
            <Grid>
              <Row>
                {dsptoken &&
                  <Cell sizeLarge={1} sizeMedium={12} >
                    <div className={styles.LeftPane}>
                      <Left sidebar={sidebar} toggleSidebar={toggleSidebar} />
                    </div>
                  </Cell>
                }
                <Cell sizeLarge={dsptoken ? 11 : 12} sizeMedium={12} >
                  <Routes />
                </Cell>
              </Row>
            </Grid>
          </Page>
        </Switch>
      </Router>
    </div>
  );
};

App.propTypes = {
  toggleSidebar: PropTypes.func,
  sidebar: PropTypes.bool,
};

export default App;
