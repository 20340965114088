// third party libs
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

//piggybank
import IconExport from '@piggybank/core/components/Icon/wrapped-icons/IconExport';
import IconUser from '@piggybank/core/components/Icon/wrapped-icons/IconUser';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

//styles and NLS
import styles from './styles.pcss';
import nls from './nls/strings_nls';

// local components
import { ChevronDown } from '@sgp-common/components/Page/Icon';
import { sessionClear } from '@sgp/lib/util/utilities';
import { useDspContext } from '@sgp/views/Context';
import { agentDetails } from '@sgp/lib/api';

const Myaccount = ({ NLS }) => {
  
  const [isShowMyProfileSet, setShowMyProfile] = useState(false);
  const [agentBasicInfo, setAgentBasicInfo] = useState({});
  const myAccountRef = useRef(null);
  const { dsptoken, dspTokenDispatch } = useDspContext();

  const showMyProfile = useCallback(() => {
    setShowMyProfile({ 'isShowMyProfileSet': !isShowMyProfileSet.isShowMyProfileSet });
  }, [isShowMyProfileSet]);

  useEffect(() => {
    document.addEventListener('click', handleMyAccountOutSideClick);
    return () => {
      document.removeEventListener('click', handleMyAccountOutSideClick);
    };
  }, []);

  const retrieveAgentDetails = async () => {
    try {
      sessionStorage.setItem('headerType', 'AGENTINFO');
      const agentDetail = await (agentDetails());                
      if(agentDetail?.agentBasicInfo) {
        sessionStorage.setItem('agentBasicInfo', JSON.stringify({ 
                                                  firstName: agentDetail?.agentBasicInfo?.firstName,
                                                  lastName: agentDetail?.agentBasicInfo?.lastName,
                                                  id: btoa(agentDetail?.agentBasicInfo?.agentUniqueID)
                                                })
        );
        setAgentBasicInfo(JSON.parse(sessionStorage.getItem('agentBasicInfo')))  
      }
    } catch (e) {
        console.log(e);
    }
  }

  useEffect(() => {
      if(!sessionStorage.getItem('agentBasicInfo')) retrieveAgentDetails(); 
      else setAgentBasicInfo(JSON.parse(sessionStorage.getItem('agentBasicInfo')));
  }, []);

  const handleMyAccountOutSideClick = useCallback((e) => {
    if (myAccountRef.current?.contains(e.target)) { return; }
    setShowMyProfile({ isShowMyProfileSet: false });
  }, []);

  const showHideBox = { display: (isShowMyProfileSet.isShowMyProfileSet ? 'flex' : 'none') };

  const logout = () => {
    sessionClear();
    dspTokenDispatch({ type: 'settoken', payload: { value: '' } });
  };

  return (
    <>
      <div className={styles.Userbox} onClick={() => showMyProfile()} ref={myAccountRef} tabIndex="0">
        <IconUser />
        <span className={styles.Username}>{ !agentBasicInfo?.firstName && !agentBasicInfo?.lastName ? '' : `${agentBasicInfo?.firstName} ${agentBasicInfo?.lastName}` }</span>
        <span><ChevronDown /></span>
        <ul style={showHideBox} id="myAcnt">
          <li className={styles.Active}><Link to="/myprofile"><IconUser width={'1.425em'} /><span>{NLS`myProfile`}</span></Link></li>
          <li onClick={() => logout()}><Link to="/logout"><IconExport width={'1.425em'} /><span>{NLS`logout`}</span></Link></li>
        </ul>
      </div>
    </>
  );
};

export default withNLS(nls)(Myaccount);
