// third party libs
import React, { useContext, useReducer } from 'react';
import Cookies from 'universal-cookie';

const cookie = new Cookies();
const initialContext = { value: cookie.get('dspSessionDup') || '' };
const DspContext = React.createContext(initialContext);

const DspReducer = (state, action) => {
  switch (action.type) {
  case 'settoken': {
    return action.payload;
  }
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
};

const DspProvider = ({ children }) => {
  const [dsptoken, dspTokenDispatch] = useReducer(DspReducer, initialContext);
  let value = { dsptoken, dspTokenDispatch };
  return (
    <DspContext.Provider value={value}>
      {children}
    </DspContext.Provider>
  );
};

const DspContextConsumer = DspContext.Consumer;

const useDspContext = () => useContext(DspContext);

export { DspProvider, useDspContext, DspContextConsumer };
