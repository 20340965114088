import React from 'react';
import ReactDOM from 'react-dom';
import App from './views';
import { DspProvider } from '@sgp/views/Context';

window.locale = 'en';
const props = {};

const renderApp = () => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', render);
  } else {
    render();
  }

  function render(event) {
    if (event) {
      document.removeEventListener('DOMContentLoaded', render);
    }
    ReactDOM.render(
      <DspProvider>
        <App props={props}/>
      </DspProvider>,
      document.getElementById('root'));
  }
};

renderApp();

if (module.hot) {
  module.hot.accept(['@sgp'], () => {
    renderApp();
  });
}

