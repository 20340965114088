import api from '@sgp/config/api-urls';
import { createFetcher } from '@lib/fetch';
import getHeaders from '@sgp/lib/util/header';
import useApiConfig from '@shared/hooks/useApiConfig';
import { sessionClear } from '@sgp/lib/util/utilities';
import { getHeadersForAuthCode } from '@sgp/lib/util/env-urls';

// entity specific headers
const STATIC_HEADERS = getHeaders;
const fetch = createFetcher(STATIC_HEADERS());

//below is temporary for unit testing
let API_CONFIG;
try {
  API_CONFIG = useApiConfig(api);
} catch (e) {
  API_CONFIG = api;
}

// Method to validate 400 and  401 error message for DSP Flow and Normal API Flow
const validateErrorMessage = (status, errorBody) => {  
  let errorMessage;
  if(errorBody?.errorInfo || (sessionStorage.getItem('headerType') && sessionStorage.getItem('headerType') === 'LOGIN')){
    errorMessage = 'Unable to load the data at the moment. Please try again later.';
    return errorMessage;
  } else {
    // if(status === 400 && 
    //   [
    //     'Bad request: general header fields missing',
    //     'Bad request: protected header fields found', 
    //     'Bad request: Requested output token type not found'
    //   ].indexOf(errorBody?.message) >= 0
    // ) {
    //   errorMessage = errorBody?.message
    // } else {
    //   errorMessage = 'Your session timed out, please re-login.'; // errorBody?.message
    // }
    errorMessage = 'Your session timed out, please re-login.';
    sessionStorage.setItem('loginError', errorMessage);
    sessionClear();
    window.location.replace(`${window.location.origin}/logout`);
    return errorMessage;
  }
}

const handleResponse = async (response) => {
  let body;
  try {
    body = response.status === 204 ? {} : response.json ? await response.json() : {};
  } catch (e) {
    body = { 'errorInfo': { 'code': 'ERR_api', 'causes': ['Unable to load the data at the moment. Please try again later.'] } }; // handle PAPI service down and the return value from gateway is not json data format
  }

  const isTechnicalError = `${response.status}`[0] === '5' ? true : false;
  
  const res = {
    errorInfo: body.errorInfo || body.error || body.message,
    status: response.status,
    isTechnicalError,
  };
  if (response.ok) {
    if (res.errorInfo) {
      return res;
    }
    return body;
  } else {
    // to catch and send the error response to front-end
    let causes;
    switch (response.status) {
    case 400:
      causes = 'Unable to load the data at the moment. Please try again later.';
      break;
    case 401:
      causes = validateErrorMessage(response.status,body);
      break;
    case 404:
      causes = 'Unable to load the data at the moment. Please try again later.';
      break;
    case 500:
      causes = 'Unable to load the data at the moment. Please try again later.';
      break;
    default:
      causes = 'Unable to load the data at the moment. Please try again later.';
    }
  // never change errorInfo format. Unless it will break down Notification Message of all the pages
  body = { 'errorInfo': { 'code': 'ERR_api', 'causes': [causes] } }; // handle PAPI service down and the return value from gateway is not json data format
  return body;
  }
};

export const overview = async payload => {
  let { overview: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const myCustomers = async payload => {
  let { myCustomers: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const myApplications = async payload => {
  let { myApplications: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders(),
    })
  );
};

export const customerPageStub2 = async payload => {
  let { customerPageStub2: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};
export const policyInfo = async payload => {
  let { policyInfo: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const policyHolderDetails = async payload => {
  let { policyHolderDetails: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const policyDetails = async payload => {
  let { policyDetails: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const libraryDetails = async payload => {
  let { libraryDetails: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const myCampaigns = async payload => {
  let { myCampaigns: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

export const campaignDocumentDownload = async payload => {
  let { campaignDocumentDownload: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
}

// global search api
export const myCustomersSearch = async payload => {
  let { myCustomersSearch: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

// download library document api
export const downloadDocuments = async payload => {
  let { downloadDocuments: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

// news api
export const newsDetails = async payload => {
  let { newsDetails: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
};

// agent api
export const agentDetails = async payload => {
  let { agentDetails: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: { ...getHeaders(), 'x-hsbc-operation-code': sessionStorage.getItem('headerType') }
    })
  );
};

export const notificationPreference = async payload => {
  let { notificationPreference: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: { ...getHeaders(), 'x-hsbc-operation-code': sessionStorage.getItem('headerType') }
    })
  );
};

export const authCodeDetail = async payload => {
  let { authCodeDetail: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeadersForAuthCode()
    })
  );
}

// news document download api
export const newsDocumentDownload = async payload => {
  let { newsDocumentDownload: { url, method } } = API_CONFIG;
  return await handleResponse(
    await fetch({
      url,
      method,
      payload,
      headers: getHeaders()
    })
  );
}