export default {
    Continue: 'Continue',
    Logout: 'Logout',
    Login: 'OK',
    wannaContinueText: 'Do you want to continue using your app?',
    wannaContinueHeader: 'Due to inactivity, we will log you out in ',
    wannaContinueTitle: 'We\'re about to log you out',
    sessionTimeoutTitle: 'Your session timed out',
    idleTimeoutText: 'Due to inactivity this session has ended. You\'ll need to restart the process to continue with your request.',
    sessionTimeoutText: 'Due to time limits exceeded, we\'ve logged you out'
}