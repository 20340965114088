// third party libraries
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

// local components and data
import { useDspContext } from '@sgp/views/Context';
import Logout from '@sgp/HOC/withUserSessionMonitor/Logout';
import Warning from '@sgp/HOC/withUserSessionMonitor/Warning';
import { sessionClear } from '@sgp/lib/util/utilities';
import { WARN_TIMEOUT_1, WARN_TIMEOUT_2, IDLE_LOGOUT_TIMEOUT, AUTO_LOGOUT_TIMEOUT } from '@sgp/data/constants';

const withUserSessionMonitor = ProtectedComponent => props => {
  const location = useLocation();
  const history = useHistory();
  const cookie = new Cookies();
  let { dspTokenDispatch } = useDspContext();

  let ACTUAL_IDLE_WARN_TIMEOUT;
  let IDLE_WARN_TIMEOUT_MILISECONDS;
  let IDLE_LOGOUT_MILISECONDS;
  let REMAINING_TIME_FRAME;
  let idleLogoutTimeout;
  let idleWarnTimeout;
  let autoLogoutTimer;
  let canReset = false;

  const events = [
    'click',
    'keypress',
    'load',
    'mousedown',
    'scroll',
  ];

  const [showWarnMessage, setShowWarnMessage] = useState(false);
  const [showLogout, setLogout] = useState(false);
  const [logoutReason, setLogoutReason] = useState('idle');
 

  const login = () => {
    dspTokenDispatch({ type: 'settoken', payload: { value: '' } }); 
    history.replace('/logout');
    setLogout(false);
  }

  const logout = (lReason = null) => {
    if (cookie.get('dspSessionDup')) {      
      // Remove all the session data
      sessionClear();

      // set a reason of logout based on this advisor will get message notification
      setLogoutReason(lReason);
      
      // hide the warn popup
      setShowWarnMessage(false);

      // if advisor manually choses Logout button from Warning Popup then directly set to loginchoice page
      // else show a popup that why advisor has been logged out
      if (lReason && ['sessionexpired', 'idle'].indexOf(lReason) >= 0 ){
        setLogout(
          ["", "/"].indexOf(window.location.href.split('/loginchoice')[1]) < 0
          &&
          ["", "/"].indexOf(window.location.href.split('/logout')[1]) < 0
        );
      } else {
        login()
      }
    } else if(sessionStorage.getItem('showWarnMessage')) {
      setShowWarnMessage(false);
      login()
    }
  };

  const warn = () => {
    if (cookie.get('dspSessionDup')) {
      setShowWarnMessage(true)
      sessionStorage.setItem('showWarnMessage', 1);
    } 
  };
  
  useEffect(() => {    
    let lastX = 0, lastY = 0;
    // do 10-second intervals to reset timeouts to prevent too much execution
    setInterval(() => resetTimeouts(), 10000);

    // Register Event Listeners
    events.map(event => window.addEventListener(event, () => canReset = true));

    // Special Handling for Mouse Movements -> esp. for faulty mouse
    document.addEventListener('mousemove', function (evt) {
      if (Math.abs(lastX - evt.x) > 5 || Math.abs(lastY - evt.y) > 5) {
        canReset = true;
        lastX = evt.x;
        lastY = evt.y;
      }
    }, false);

    // Initialize timeout parameters
    initiateTimeouts();

    // Start Monitoring
    setTimeouts();
    
    return () => {
      // De-Register Event Listeners
      events.map(event => window.removeEventListener(event, resetTimeouts));

      // Stop Monitoring
      clearTimeouts();
    };
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('showWarnMessage')
    // Start Monitoring for Auto Logout for remaining minutes
    if (localStorage.getItem('startTime')) {
      REMAINING_TIME_FRAME = 1000 * 60 * AUTO_LOGOUT_TIMEOUT - (new Date().getTime() - Number(localStorage.getItem('startTime')));
      setAutoLogoutTimers();
    }
    
    return () => clearAutoLogoutTimers();
  }, [location.key]);

  const initiateTimeouts = () => {
    // At first time after login, Idle time should be specified time
    // Next time it should be less than previous
    // Adjust the timelimit and store it to seesion storage to track whether idle session is first or not
    ACTUAL_IDLE_WARN_TIMEOUT = localStorage.getItem('idleWarnCounter') ? WARN_TIMEOUT_2 : WARN_TIMEOUT_1;
    IDLE_WARN_TIMEOUT_MILISECONDS = 1000 * 60 * ACTUAL_IDLE_WARN_TIMEOUT;
    IDLE_LOGOUT_MILISECONDS = 1000 * 60 * (ACTUAL_IDLE_WARN_TIMEOUT + IDLE_LOGOUT_TIMEOUT);
  };

  const setTimeouts = () => {
    idleWarnTimeout = setTimeout(warn, IDLE_WARN_TIMEOUT_MILISECONDS);
    idleLogoutTimeout = setTimeout(() => logout('idle'), IDLE_LOGOUT_MILISECONDS);
  };

  const clearTimeouts = () => {
    idleWarnTimeout && clearTimeout(idleWarnTimeout);
    idleLogoutTimeout && clearTimeout(idleLogoutTimeout);
  };

  const resetTimeouts = () => {
    if (canReset && !sessionStorage.getItem('showWarnMessage') && cookie.get('dspSessionDup')) {
      clearTimeouts();
      initiateTimeouts();
      setTimeouts();
      canReset = false;
      // console.log('Resetting timeouts...');
    }
  };

  const setAutoLogoutTimers = () => autoLogoutTimer = setTimeout(() => logout('sessionexpired'), REMAINING_TIME_FRAME);

  const clearAutoLogoutTimers = () => autoLogoutTimer && clearTimeout(autoLogoutTimer);

  const onHideWarnMessage = () => {
    setShowWarnMessage(false);
    sessionStorage.removeItem('showWarnMessage')
    // if advisor already logged out from another tab then on hide of the pop up redirect it to login choice page
    // else store in localstorage so advisor could be tracked for next idle time limit even after page refreshes
    if (cookie.get('dspSessionDup')) localStorage.setItem('idleWarnCounter', Number(localStorage.getItem('idleWarnCounter')) + 1 || 1);
    else login()
  };

  return (
    <>
      <ProtectedComponent {...props} />
      { showWarnMessage && <Warning onHideWarnMessage = {onHideWarnMessage} onLogout = {logout}/> }
      { showLogout && <Logout logoutReason = {logoutReason} onLogin = {login}/> }
    </>
  );
};

export default withUserSessionMonitor;