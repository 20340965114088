import React from 'react';
const PendingItems = props => (
  <svg width="19px" height="20px" {...props} viewBox="0 0 19 20" className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path id="path-2" d="M 1.34261 7.82406 C 2.23728 8.71933 3.41061 9.16667 4.58333 9.16667 C 5.64178 9.16667 6.69778 8.79878 7.55211 8.07033 L 10.4659 10.9835 L 10.9847 10.4653 L 8.07094 7.5515 C 9.60667 5.75056 9.52722 3.04394 7.82528 1.342 C 6.92939 0.447333 5.75606 0 4.58333 0 C 3.41061 0 2.23728 0.447333 1.34261 1.34261 C -0.447333 3.13256 -0.447333 6.03411 1.34261 7.82406 Z M 0.733333 4.58333 C 0.733333 3.55483 1.13422 2.58806 1.86083 1.86083 C 2.58744 1.13361 3.55483 0.733333 4.58333 0.733333 C 5.61183 0.733333 6.57861 1.13361 7.30583 1.86083 C 8.80672 3.36172 8.80672 5.80433 7.30583 7.30583 C 6.57861 8.03306 5.61183 8.43333 4.58333 8.43333 C 3.55483 8.43333 2.58806 8.03306 1.86083 7.30583 C 1.13361 6.57861 0.733333 5.61183 0.733333 4.58333 Z" />
    </defs>
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="My-Applications-(Desktop)" transform="translate(-1326 -278)">
        <g id="Content" transform="translate(140 82)">
          <g id="Table" transform="translate(20 140)">
            <g id="Customer-table-row-">
              <g id="Row1" transform="translate(0 45)">
                <g id="2-lines-">
                  <g id="System-icons/-Document-confidential-/-Copy-2" transform="translate(1163 11)">
                    <rect id="Container" opacity="0" fill="#ffffff" x="0" y="0" width="22" height="22" />
                    <path id="Combined-Shape" fill="#333333" d="M 17.3556 -3.55271e-014 L 22 4.64444 L 22 19.5556 L 13.6889 19.5556 L 13.6889 18.0889 L 20.5333 18.0889 L 20.5333 6.11111 L 15.8889 6.11111 L 15.8889 1.46667 L 6.35556 1.46667 L 6.35556 5.89111 C 6.27244 5.88622 6.19422 5.86667 6.11111 5.86667 C 5.68822 5.86667 5.28244 5.93389 4.88889 6.03044 L 4.88889 6.03044 L 4.88889 -3.55271e-014 L 17.3556 -3.55271e-014 Z M 17.3556 2.07411 L 17.3556 4.64444 L 19.9259 4.64444 L 17.3556 2.07411 Z" />
                    <g id="Icons---System-icons/-Search-/" transform="translate(9.5 12.5) scale(-1 1) translate(-9.5 -12.5) translate(4 7)">
                      <polygon id="Container" opacity="0" fill="#ffffff" transform="translate(5.5 5.5) rotate(-180) translate(-5.5 -5.5)" points="8.66912e-010,-8.6706e-010 11,-8.6706e-010 11,11 8.66912e-010,11" />
                      <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-2" />
                      </mask>
                      <use id="Icon-colour" fill="#333333" stroke="#333333" strokeWidth="0.6" xlinkHref="#path-2" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(PendingItems);
