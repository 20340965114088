import React from 'react';
const CampaignFilled = props => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M22.6666667,15.6666667 L22.6666667,24 L13,24 L13,15.6666667 L22.6666667,15.6666667 Z M11,15.6666667 L11,24 L1.33333333,24 L1.33333333,15.6666667 L11,15.6666667 Z M20.6426667,5.33333333 C21.6333333,4.02933333 21.5466667,2.16533333 20.3573333,0.973333333 C19.0586667,-0.324 16.9466667,-0.325333333 15.6493333,0.974666667 L11.9986667,4.62533333 L8.348,0.973333333 C7.04933333,-0.322666667 4.93866667,-0.321333333 3.64,0.974666667 C2.452,2.16533333 2.36533333,4.02933333 3.356,5.33333333 L1.33333333,5.33333333 L1.33333333,13.6666667 L11,13.6666667 L11,6.93333333 L13,6.93333333 L13,13.6666667 L22.6666667,13.6666667 L22.6666667,5.33333333 L20.6426667,5.33333333 Z M5.55333333,5.33333333 L4.772,4.552 C4.09866667,3.87733333 4.09866667,2.78 4.772,2.10666667 C5.444,1.432 6.54266667,1.432 7.216,2.10533333 L10.444,5.33333333 L5.55333333,5.33333333 Z M19.2253333,4.55066667 L18.4426667,5.33333333 L13.5533333,5.33333333 L16.7813333,2.10666667 C17.4546667,1.432 18.552,1.43333333 19.2253333,2.10533333 C19.9,2.78 19.9,3.87733333 19.2253333,4.55066667 Z" id="CampaignFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#CampaignFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#CampaignFilled"></use>
      </g>
    </g>
  </svg>
);

export default React.memo(CampaignFilled);
