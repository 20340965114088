import React from 'react';
const NewsFilled = props => (
  <svg width="22px" height="24px" viewBox="0 0 22 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M22.6666667,0 L22.6666667,24 L1.33333333,24 L1.33333333,0 L22.6666667,0 Z M10.6666667,11.2 L5.33333333,11.2 L5.33333333,12.8 L10.6666667,12.8 L10.6666667,11.2 Z M10.6666667,18.4 L5.33333333,18.4 L5.33333333,20 L10.6666667,20 L10.6666667,18.4 Z M10.6666667,14.8 L5.33333333,14.8 L5.33333333,16.4 L10.6666667,16.4 L10.6666667,14.8 Z M13.3333333,20 L18.6666667,20 L18.6666667,11.2 L13.3333333,11.2 L13.3333333,20 Z M14.9333333,12.8 L17.0666667,12.8 L17.0666667,18.4 L14.9333333,18.4 L14.9333333,12.8 Z M18.6666667,4 L5.33333333,4 L5.33333333,5.6 L18.6666667,5.6 L18.6666667,4 Z M18.6666667,7.6 L5.33333333,7.6 L5.33333333,9.2 L18.6666667,9.2 L18.6666667,7.6 Z" id="NewsFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="News--(Desktop)" transform="translate(-29.000000, -483.000000)">
        <g id="left-navigation-News-" transform="translate(0.000000, 62.000000)">
          <g id="left-navigation-">
            <g id="Overview">
              <g>
                <g>
                  <g id="News-active" transform="translate(0.000000, 405.000000)">
                    <g id="News-inactive">
                      <g id="Tab-6">
                        <g id="news-" transform="translate(28.000000, 16.000000)">
                          <g id="System-icons/-View-/">
                            <rect id="Container" fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24"></rect>
                            <mask id="mask-2" fill="white">
                              <use xlinkHref="#NewsFilled"></use>
                            </mask>
                            <use id="Icon-colour" fill="#DB0011" xlinkHref="#NewsFilled"></use>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(NewsFilled);
