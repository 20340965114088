// third party libraries
import React from 'react';
import { Redirect } from 'react-router';
import Cookies from 'universal-cookie';

// local components and data
import { sessionClear } from '@sgp/lib/util/utilities';
import { useDspContext } from '@sgp/views/Context';
import { AUTO_LOGOUT_TIMEOUT } from '@sgp/data/constants';

const withAuthenticateRouting = ProtectedComponent => props => {
  const cookie = new Cookies();
  let { dsptoken } = useDspContext();

  dsptoken = dsptoken.value || cookie.get('dspSessionDup');

  const startTime = +localStorage.getItem('startTime');
  const timeLimit = 1000 * 60 * AUTO_LOGOUT_TIMEOUT;
  
  // Validate following things
  // 1. the Session Cookie Present 
  // 2. time frame must not cross above 30 mins
  if (cookie.get('dspSessionDup') && (startTime + timeLimit) > new Date().getTime()) {  
    return <ProtectedComponent {...props} />;
  } else {
    // remove all stored sessions
    sessionClear();
    return <Redirect to="/loginchoice" />;
  }
};

export default withAuthenticateRouting;