import React from 'react';
const ApplicationsFilled = props => (
  <svg width="20px" height="24px" viewBox="0 0 20 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M20,4.69066667 L20,2.42933333 L17.5706667,-1.21384384e-13 L4,-1.21384384e-13 L4,1.59866667 L16.908,1.59866667 L20,4.69066667 Z M16.2666667,3.2 L21.3333333,8.26666667 L21.3333333,24 L2.66666667,24 L2.66666667,3.2 L16.2666667,3.2 Z M19.0706667,8.26666667 L16.2666667,8.26666667 L16.2666667,5.46266667 L19.0706667,8.26666667 Z" id="ApplicationsFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#ApplicationsFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#ApplicationsFilled"></use>
      </g>
    </g>
  </svg>
);

export default React.memo(ApplicationsFilled);
