// third party libs
import React, { memo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import ErrorBoundary from 'react-error-boundary';

//styles
import styles from './styles.pcss';

// local components
import withAuthenticateRouting from '@sgp/HOC/withAuthenticateRouting';
import withUserSessionMonitor from '@sgp/HOC/withUserSessionMonitor';

const GenericErrorHandler = loadable(() => import(/* webpackChunkName: 'GenericErrorHandler' */'@sgp-common/components/Errors/GenericErrorHandler'));

const PageNotFound = loadable(() => import(/* webpackChunkName: 'PageNotFound' */'@sgp-common/components/Errors/PageNotFound'));

const Overview = loadable(() => import(/* webpackChunkName: 'Overview' */ './journey/Overview'));

const Customers = loadable(() => import(/* webpackChunkName: 'Customers' */ './journey/Customers'));

const Applications = loadable(() => import(/* webpackChunkName: 'Applications' */ './journey/Applications'));

const Campaigns = loadable(() => import(/* webpackChunkName: 'Campaigns' */ './journey/Campaigns'));

const Library = loadable(() => import(/* webpackChunkName: 'Library' */ './journey/Library'));

const News = loadable(() => import(/* webpackChunkName: 'News' */ './journey/News'));

const Contacts = loadable(() => import(/* webpackChunkName: 'Contacts' */ './journey/Contacts'));

const MyProfile = loadable(() => import(/* webpackChunkName: 'MyProfile' */ './journey/MyProfile'));

const LoginChoice = loadable(() => import(/* webpackChunkName: 'LoginChoice' */ './journey/LoginChoice'));

const Authorize = loadable(() => import(/* webpackChunkName: 'Authorize' */ './journey/Authorize'));

const Logout = loadable(() => import(/* webpackChunkName: 'Logout' */ './journey/Logout'));

const Routes = memo(() => {
  return (
    <div className={styles.MainContainer}>
      <div className={styles.MainPageArea}>
        <ErrorBoundary FallbackComponent={GenericErrorHandler}>
          <Switch>
            <Route exact path="/"> <Redirect to="/loginchoice" /></Route>
            <Route exact path="/overview" component={withAuthenticateRouting(Overview)} />
            <Route path="/customers" component={withAuthenticateRouting(Customers)} />
            <Route exact path="/applications" component={withAuthenticateRouting(Applications)} />
            <Route exact path="/campaigns" component={withAuthenticateRouting(Campaigns)} />
            <Route exact path="/library" component={withAuthenticateRouting(Library)} />
            <Route exact path="/news" component={withAuthenticateRouting(News)} />
            <Route exact path="/contacts" component={withAuthenticateRouting(Contacts)} />
            <Route exact path="/myprofile" component={withAuthenticateRouting(MyProfile)} />
            <Route exact path="/loginchoice" component={LoginChoice} />
            <Route exact path="/logout" component={Logout}/>
            <Route exact path="/authenticated" component={Authorize} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </ErrorBoundary>
      </div>
    </div>
  );
});

export default withUserSessionMonitor(Routes);
