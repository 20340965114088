import { DELETE, GET, POST, PUT } from '@lib/data/api-types';
var API_URL = 'http://localhost:3003';
var XAPI_BASE_URL = "".concat(API_URL, "/");
var stubUrls = function stubUrls(config) {
  Object.keys(config).map(function (key) {
    if (config[key].url.indexOf('localhost') !== -1) {
      config[key].url = "".concat(config[key].url.replace(/^(https?:)?(\/\/[^/]+)/, '$1$2/sgp/stub'), ".json");
      config[key].method = GET;
    }
    return config;
  });
};
var config = {
  overview: {
    url: "".concat(XAPI_BASE_URL, "dashboard"),
    method: GET
  },
  myCustomers: {
    url: "".concat(XAPI_BASE_URL, "myCustomers"),
    method: POST
  },
  myApplications: {
    url: "".concat(XAPI_BASE_URL, "myApplications"),
    method: POST
  },
  policyInfo: {
    url: "".concat(XAPI_BASE_URL, "policyInfo"),
    method: GET
  },
  policyHolderDetails: {
    url: "".concat(XAPI_BASE_URL, "policyHolderDetailsResponse"),
    method: GET
  },
  policyDetails: {
    url: "".concat(XAPI_BASE_URL, "policyDetails"),
    method: GET
  },
  myCustomersSearch: {
    url: "".concat(XAPI_BASE_URL, "myCustomers_1"),
    method: POST
  },
  libraryDetails: {
    url: "".concat(XAPI_BASE_URL, "library"),
    method: GET
  },
  downloadDocuments: {
    url: "".concat(XAPI_BASE_URL, "libraryGetDocuments"),
    method: GET
  },
  myCampaigns: {
    url: "sgp/stub/campaigns.json",
    method: POST
  },
  campaignDocumentDownload: {
    url: "sgp/stub/campaignsDocument.json",
    method: POST
  },
  newsDetails: {
    url: "sgp/stub/news.json",
    method: GET
  },
  agentDetails: {
    url: "".concat(XAPI_BASE_URL, "agent"),
    method: GET
  },
  notificationPreference: {
    url: "sgp/stub/notification.json",
    method: PUT
  },
  authCodeDetail: {
    url: "sgp/stub/authcode.json",
    method: POST
  },
  newsDocumentDownload: {
    url: "sgp/stub/newsDocument.json",
    method: POST
  }
};
if (APP_ENV === 'DIT') {
  //local environment

  config.overview.url = 'https://ins-statistics-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/statistics_details';
  config.myCustomers.url = 'https://ins-policies-detail-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/my_customer_details';
  config.myApplications.url = 'https://ins-policies-detail-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/my_application_details';
  config.policyHolderDetails.url = 'https://ins-policy-holder-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/policies/{policies}/policy_holder_details/{policy_holder_details}';
  config.policyDetails.url = 'https://ins-policy-coverage-details-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/policy_coverage_details/{policyId}';
  config.myCustomersSearch.url = 'https://ins-search-customers-detail-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/search_customer';
  config.libraryDetails.url = 'https://ins-documents-forms-search-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/documents/search';
  config.downloadDocuments.url = 'https://ins-documents-forms-retrieval-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/documents/retrieve/{documentId}';
  config.agentDetails.url = 'https://ins-get-user-profile-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/get_user_profile';
  config.notificationPreference.url = 'https://ins-update-notification-preferences-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/notificationPref';
  config.myCampaigns.url = "https://ins-campaign-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/campaigns";
  config.newsDetails.url = 'https://ins-news-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/news_details';
  config.campaignDocumentDownload.url = 'https://ins-campaign-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/get_document';
  config.newsDocumentDownload.url = 'https://ins-campaign-service-1.hsbc-insh-sdpeapi-sit-sct.svc.default.shp.ape1.pre-prod.aws.cloud.hsbc/get_document';
} else {
  var namespace = '';
  switch (APP_ENV) {
    case 'DEV':
    case 'SIT':
      namespace = 'sct';
      break;
    case 'UAT':
      namespace = 'mct';
      break;
    case 'PREPROD':
      namespace = 'cert';
      break;
    case 'PROD':
      namespace = 'prod';
      break;
  }
  config.overview.url = "/ins-statistics-service-".concat(namespace, "-proxy/v1/statistics_details");
  config.myCustomers.url = "/ins-policies-detail-service-".concat(namespace, "-proxy/v1/my_customer_details");
  config.myApplications.url = "/ins-policies-detail-service-".concat(namespace, "-proxy/v1/my_application_details");
  config.policyHolderDetails.url = "/ins-policy-holder-service-".concat(namespace, "-proxy/v1/policies/{policies}/policy_holder_details/{policy_holder_details}");
  config.policyDetails.url = "/ins-policy-coverage-details-service-".concat(namespace, "-proxy/v1/policy_coverage_details/{policyId}");
  config.myCustomersSearch.url = "/ins-search-customers-detail-service-".concat(namespace, "-proxy/v1/search_customer");
  config.libraryDetails.url = "/ins-documents-forms-search-service-".concat(namespace, "-proxy/v1/documents/search");
  config.downloadDocuments.url = "/ins-documents-forms-retrieval-service-".concat(namespace, "-proxy/v1/documents/retrieve/{documentId}");
  config.agentDetails.url = "/ins-get-user-profile-service-".concat(namespace, "-proxy/v1/get_user_profile");
  config.notificationPreference.url = "/ins-update-notification-preferences-service-".concat(namespace, "-proxy/v1/notificationPref");
  config.myCampaigns.url = "/ins-campaign-service-".concat(namespace, "-proxy/v1/campaigns");
  config.authCodeDetail.url = "/secp-authorisation-authcode-sgdp-proxy/v1/oauth.jsp";
  config.newsDetails.url = "/ins-news-service-".concat(namespace, "-proxy/v1/news_details");
  config.campaignDocumentDownload.url = "/ins-campaign-service-".concat(namespace, "-proxy/v1/get_document");
  config.newsDocumentDownload.url = "/ins-campaign-service-".concat(namespace, "-proxy/v1/get_document");
}
stubUrls(config);
export default config;