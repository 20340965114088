import React from 'react';
const LibraryFilled = props => (
  <svg width="24px" height="23px" viewBox="0 0 24 23" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M7.11466667,0 C8.176,0 9.19333333,0.421333333 9.944,1.172 L9.944,1.172 L11,2.228 L11,23 L10.504,22.5053333 C9.75466667,21.7546667 8.73733333,21.3333333 7.676,21.3333333 L7.676,21.3333333 L0,21.3333333 L0,0 Z M24,0 L24,21.3333333 L16.324,21.3333333 C15.2626667,21.3333333 14.2453333,21.7546667 13.4946667,22.5053333 L13.4946667,22.5053333 L13,23 L13,2.228 L14.0573333,1.172 C14.8066667,0.421333333 15.824,0 16.8853333,0 L16.8853333,0 L24,0 Z" id="LibraryFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#LibraryFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#LibraryFilled"></use>
      </g>
    </g>
  </svg>
);
export default React.memo(LibraryFilled);
