// third party libs
/* istanbul ignore file */
import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//piggybank
import { IconSearch } from '@piggybank/core/components/Icon';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

//styles and NLS
import styles from './styles.pcss';
import nls from './../nls/strings_nls';

//local components
import Select from './Select';

const SearchBox = ({ NLS, ...props }) => {

  let history = useHistory();
  const [isSearchSet, setBoxSearch] = useState(false);
  const [leftPostion, setPostion] = useState(0);
  let transition = { transition: 'all 0.5s ease-out' };

  const searchBoxClink = useCallback(() => {
    setBoxSearch({ 'isSearchSet': !isSearchSet });
  }, []);

  const searchValue = (data) => {
    const option = appStatus.find((v) => v.value === data.value[0]);
    let finalSearchData = [...data?.value , option.label];
    history.push({ pathname: '/customers', params: { search: finalSearchData } });
    setPostion(parseInt(0));
    setBoxSearch(false);
  };

  const onClose = useCallback(() => {
    transition = { transition: 'all 0.5s ease-out' };
    setPostion(parseInt(leftPostion));
    setBoxSearch(false);
  }, []);

  const targetRef = useCallback(domNode => {
    if (domNode) {
      let newLeft = domNode.getBoundingClientRect().left;
      if (newLeft < 800)
        setPostion(-(newLeft - 8)); // still needs to check for better solution
      else
        setPostion(-320);
    }
  }, []);

  const appStatus = [{ label: NLS`policyOwner`, value: '1_POLICYOWNER' },
    { label: NLS`lifeInsured`, value: '2_LIFEINSURED' },
    { label: NLS`NIRCPassportNumber`, value: '3_DOCUMENTID' },
    { label: NLS`policyNumber`, value: '4_POLICYID' },
    { label: NLS`productName`, value: '5_PRODUCTNAME' },
  ];

  return (
    <>
      {!isSearchSet &&
        <div className={styles.Search} onClick={() => searchBoxClink()} tabIndex="0">
          <IconSearch marginBottom={0} />
        </div>
      }
      {isSearchSet &&
        <div className={styles.SearchBoxContainer}>
          <div className={styles.SearchBoxAlignment} ref={targetRef} style={(leftPostion !== 0 ? { left: `${leftPostion}px`, transition } : {})}>
            <Select
              name='searchapps'
              options={appStatus}
              searchValue={searchValue}
              onClose={onClose}
              onChange={() => { }}
            />
          </div>
        </div>
      }
    </>
  );
};
export default withNLS(nls)(SearchBox);
