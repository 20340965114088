import React from 'react';
const OverviewFilled = props => (
  <svg width="24px" height="24px" viewBox="0 0 18 18" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M9,10 C9.55228475,10 10,9.55228475 10,9 C10,8.44771525 9.55228475,8 9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 Z M9,0 C4.029,0 0,4.029 0,9 C0,13.971 4.029,18 9,18 C13.971,18 18,13.971 18,9 C18,4.029 13.971,0 9,0 Z M3.495,9.517 L1.226,9.517 C1.215,9.345 1.2,9.174 1.2,9 C1.2,8.666 1.228,8.339 1.269,8.017 L3.495,8.017 L3.495,9.517 Z M4.577,5.404 L3.093,3.921 C3.42,3.541 3.782,3.193 4.176,2.883 L5.637,4.344 L4.577,5.404 Z M9,11.5 C7.619,11.5 6.5,10.381 6.5,9 C6.5,7.882 7.238,6.947 8.25,6.627 L8.25,3 L9.75,3 L9.75,6.627 C10.762,6.947 11.5,7.882 11.5,9 C11.5,10.381 10.381,11.5 9,11.5 Z M12.362,4.344 L13.823,2.883 C14.217,3.194 14.579,3.541 14.906,3.921 L13.422,5.404 L12.362,4.344 Z M14.505,9.517 L14.505,8.017 L16.731,8.017 C16.772,8.339 16.8,8.666 16.8,9 C16.8,9.174 16.785,9.345 16.774,9.517 L14.505,9.517 Z" id="OverviewFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#OverviewFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#OverviewFilled"></use>
      </g>
    </g>
  </svg>
);

export default React.memo(OverviewFilled);
