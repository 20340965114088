import React from 'react';
const CustomersFilled = props => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <path d="M15.404,9.23066667 C17.348,8.65733333 18.2,6.52533333 18.2,4.57466667 C18.2,2.21066667 17.072,0 14.344,0 C11.7493333,0 10.5946667,1.98533333 10.488,4.23333333 C13.088,4.56 14.9266667,6.43066667 15.404,9.23066667 Z M22.0573333,13.8613333 C21.8453333,13.336 21.544,12.848 21.1493333,12.4413333 C20.2986667,11.5653333 19.2546667,10.8826667 18.0373333,10.424 C17.296,10.9813333 16.4346667,11.3586667 15.492,11.532 C15.404,12.4573333 15.168,13.364 14.7826667,14.1826667 C15.9706667,14.724 17.0226667,15.4586667 17.9173333,16.38 C18.4786667,16.9573333 18.9253333,17.652 19.2453333,18.444 L19.3346667,18.6666667 L24,18.6666667 L22.0573333,13.8613333 Z M9.67733333,15.5453333 C12.3826667,15.5453333 13.5333333,13.0066667 13.5333333,10.7306667 C13.5333333,8.36666667 12.4053333,6.15466667 9.67733333,6.15466667 C6.94933333,6.15466667 5.8,8.344 5.8,10.7306667 C5.8,13.072 6.92666667,15.5453333 9.67733333,15.5453333 Z M16.4826667,17.7733333 C15.5426667,16.8053333 14.3666667,16.0733333 12.98,15.6226667 L9.67866667,18.924 L6.372,15.6173333 C4.97733333,16.0666667 3.796,16.8026667 2.852,17.7733333 C2.45733333,18.18 2.156,18.668 1.944,19.1933333 L0,24 L19.3333333,24 L17.3906667,19.1946667 C17.1786667,18.668 16.8773333,18.18 16.4826667,17.7733333 Z" id="CustomersFilled"></path>
    </defs>

    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="System-icons/-Dashboard-/-Active">
        <rect id="Container" fill="#FFFFFF" fillRule="evenodd" opacity="0" x="0" y="0" width="18" height="18"></rect>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#CustomersFilled"></use>
        </mask>
        <use id="Icon-colour" fill="#DB0011" fillRule="evenodd" xlinkHref="#CustomersFilled"></use>
      </g>
    </g>
  </svg>
);

export default React.memo(CustomersFilled);
