// third party libs
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import omit from 'lodash/fp/omit';
import { useHistory } from 'react-router-dom';

//piggybank
import IconMenuActive from '@piggybank/core/components/Icon/wrapped-icons/IconMenuActive';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

//styles and NLS
import nls from './nls/strings_nls';
import styles from './styles.pcss';

// local components
import SearchBox from './Search';
import Myaccount from './Myaccount';
import HsbcLogo from '@sgp-common/components/Page/Icon/icons/HsbcLogo';

const Header = ({
  NLS,
  component: Component = 'header',
  scrollPositionY,
  sticky = true,
  sidebar = props.sidebar,
  toggleSidebar = props.toggleSidebar,
  dsptoken = null,
  ...props
}) => {

  let history = useHistory();
  return (

    <Component
      className={cn(
        { [styles['Masthead--sticky']]: sticky }
      )}
      {...omit(['scrollPosition', 'scrollPositionX'], props)}
    >
      <div
        className={cn(styles.wrapper, {
          [styles['wrapper--sticky']]: sticky,
          [styles['wrapper--scrolled']]: sticky && scrollPositionY > 0,
        })}
      >
        <>
          <div className={styles.container}>
            <div className={styles.Menu}>
              {dsptoken && <IconMenuActive onClick={() => toggleSidebar(!sidebar)} />}
              <div className={styles.Menubar} />
            </div>
            <div className={styles.logoBox}>
              <span className={styles.logo}><HsbcLogo /></span>
              {dsptoken && <span className={styles.siteTextTile}>{NLS`siteTitle`}</span>}
            </div>
            <div className={styles.SearchAndMenuBox}>
              <div className={styles.SearchBox}>
                {dsptoken && <SearchBox />}
              </div>
              <div className={styles.Myaccount}>
                {dsptoken && <Myaccount />}
              </div>
            </div>
          </div>
        </>
      </div>
    </Component>
  );
};

Header.propTypes = {
  component: PropTypes.string,
  logoSlot: PropTypes.node,
  sticky: PropTypes.bool,
  sidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  dsptoken: PropTypes.any,
};

export default withNLS(nls)(Header);
