import React from 'react';
const HsbcLogo = props => (
  <svg width="87px" height="24px" viewBox="0 0 87 24" {...props} className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Header" transform="translate(-23.000000, -19.000000)" fillRule="nonzero">
        <g id="hsbc-life-logo">
          <g>
            <g transform="translate(23.000000, 19.000000)">
              <polygon id="Path" fill="#FFFFFF" points="35.1303797 0.273267846 11.728481 0.273267846 -3.19744231e-14 11.9008147 11.728481 23.5283616 35.1303797 23.5283616 46.8313291 11.9144781"></polygon>
              <g id="Group">
                <g transform="translate(0.000000, 0.273268)" fill="#DB0011" id="Path">
                  <polygon points="46.8313291 11.6412102 35.1303797 0 35.1303797 23.2550937"></polygon>
                  <polygon points="23.4294304 11.6412102 35.1303797 0 11.728481 0"></polygon>
                  <polygon points="0 11.6412102 11.728481 23.2550937 11.728481 0"></polygon>
                  <polygon points="23.4294304 11.6412102 11.728481 23.2550937 35.1303797 23.2550937"></polygon>
                </g>
                <g transform="translate(50.658228, 0.000000)" fill="#FFFFFF">
                  <polygon id="Path" points="6.44240506 5.84793191 2.20253165 5.84793191 2.20253165 10.0016032 0.0825949367 10.0016032 0.0825949367 0.273267846 2.20253165 0.273267846 2.20253165 4.2629784 6.44240506 4.2629784 6.44240506 0.273267846 8.56234177 0.273267846 8.56234177 10.0016032 6.44240506 10.0016032"></polygon>
                  <path d="M13.6832278,10.1928907 C11.5632911,10.1928907 9.82879747,9.34576034 9.80126582,7.02298365 L11.9212025,7.02298365 C11.9487342,8.06140146 12.5544304,8.68991751 13.7382911,8.68991751 C14.6193038,8.68991751 15.5829114,8.25268896 15.5829114,7.29625149 C15.5829114,6.53110152 14.9221519,6.31248725 13.8208861,5.98456583 L13.1050633,5.79327834 C11.5632911,5.35604979 10.021519,4.75486052 10.021519,3.00594631 C10.021519,0.847130323 12.0588608,0.109307138 13.903481,0.109307138 C15.8031646,0.109307138 17.4550633,0.765149969 17.4825949,2.92396595 L15.3626582,2.92396595 C15.2800633,2.02218206 14.756962,1.53029994 13.7658228,1.53029994 C12.9674051,1.53029994 12.196519,1.94020171 12.196519,2.81465882 C12.196519,3.52515522 12.8572785,3.74376949 14.2338608,4.18099805 L15.0598101,4.42693911 C16.7392405,4.94614802 17.785443,5.52001049 17.785443,7.13229079 C17.7579114,9.34576034 15.610443,10.1928907 13.6832278,10.1928907 Z" id="Path"></path>
                  <path d="M18.9693038,0.273267846 L22.3832278,0.273267846 C23.456962,0.273267846 23.8974684,0.300594631 24.310443,0.382574985 C25.4943038,0.655842831 26.4028481,1.4209928 26.4028481,2.73267846 C26.4028481,3.98971055 25.6044304,4.6182266 24.4481013,4.91882123 C25.7696203,5.16476229 26.7607595,5.82060512 26.7607595,7.26892471 C26.7607595,9.48239426 24.5582278,9.97427639 22.8512658,9.97427639 L18.9968354,9.97427639 L18.9968354,0.273267846 L18.9693038,0.273267846 Z M22.3832278,4.34495875 C23.3193038,4.34495875 24.2553797,4.15367126 24.2553797,3.03327309 C24.2553797,2.02218206 23.3468354,1.74891422 22.4933544,1.74891422 L21.0066456,1.74891422 L21.0066456,4.34495875 L22.3832278,4.34495875 Z M22.5759494,8.55328359 C23.5670886,8.55328359 24.5306962,8.33466931 24.5306962,7.13229079 C24.5306962,5.98456583 23.6772152,5.71129799 22.6860759,5.71129799 L21.0066456,5.71129799 L21.0066456,8.55328359 L22.5759494,8.55328359 Z" id="Shape"></path>
                  <path d="M32.2670886,10.1928907 C29.1009494,10.1928907 27.6968354,8.19803539 27.6968354,5.21941586 C27.6968354,2.29544991 29.2661392,0.0819803539 32.3772152,0.0819803539 C34.331962,0.0819803539 36.2316456,0.956437462 36.2867089,3.14258023 L34.0841772,3.14258023 C33.9740506,2.18614277 33.3132911,1.66693386 32.3772152,1.66693386 C30.4775316,1.66693386 29.8718354,3.71644271 29.8718354,5.27406943 C29.8718354,6.83169616 30.45,8.63526394 32.2946203,8.63526394 C33.2582278,8.63526394 33.9740506,8.11605503 34.1117089,7.13229079 L36.3142405,7.13229079 C36.0939873,9.34576034 34.3044304,10.1928907 32.2670886,10.1928907 Z" id="Path"></path>
                </g>
              </g>
              <g id="Group" transform="translate(51.000000, 14.000000)" fill="#FFFFFF">
                <polygon id="Path" points="0.0796875 0.189189189 0.9296875 0.189189189 0.9296875 9.05405405 5.6578125 9.05405405 5.6578125 9.75675676 0.0796875 9.75675676"></polygon>
                <path d="M6.2421875,0.189189189 L7.2421875,0.189189189 L7.2421875,1.18918919 L6.2421875,1.18918919 L6.2421875,0.189189189 Z M6.32552083,3.05405405 L7.15885417,3.05405405 L7.15885417,9.72972973 L6.32552083,9.72972973 L6.32552083,3.05405405 Z" id="Shape"></path>
                <path d="M10.94375,0.72972973 C9.9875,0.72972973 9.828125,1.08108108 9.828125,2.02702703 L9.828125,3.08108108 L11.2890625,3.08108108 L11.2890625,3.67567568 L9.828125,3.67567568 L9.828125,9.75675676 L9.03125,9.75675676 L9.03125,3.67567568 L7.703125,3.67567568 L7.703125,3.08108108 L9.03125,3.08108108 L9.03125,2.08108108 C9.03125,1.02702703 9.190625,0.108108108 10.571875,0.108108108 C10.8375,0.108108108 11.1296875,0.108108108 11.3953125,0.135135135 L11.3953125,0.756756757 C11.2890625,0.72972973 11.103125,0.72972973 10.94375,0.72972973 Z" id="Path"></path>
                <path d="M12.059375,6.7027027 C12.059375,8.05405405 12.803125,9.32432432 14.2375,9.32432432 C15.2734375,9.32432432 15.9375,8.67567568 16.096875,7.64864865 L16.840625,7.64864865 C16.575,9.13513514 15.5921875,9.91891892 14.13125,9.91891892 C12.1390625,9.91891892 11.2359375,8.43243243 11.2359375,6.54054054 C11.2359375,4.62162162 12.165625,2.97297297 14.2375,2.97297297 C16.3359375,2.97297297 16.89375,4.75675676 16.89375,6.54054054 L12.059375,6.54054054 L12.059375,6.7027027 L12.059375,6.7027027 Z M14.184375,3.56756757 C12.85625,3.56756757 12.1921875,4.7027027 12.0859375,5.91891892 L16.096875,5.91891892 L16.096875,5.78378378 C16.096875,4.62162162 15.4328125,3.56756757 14.184375,3.56756757 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(HsbcLogo);
