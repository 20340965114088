import React from 'react';

const NotificationErrorFilled = (props) => (
  <svg 
    width="24px" 
    height="24px" 
    viewBox="0 0 18 18"
    className="pb-core-components-Icon-icon--Icon" 
    role="img" 
    focusable="false" 
    aria-hidden="true" 
    {...props} 
  >
    <path d="M8.309 1.682L.109 15.8A.8.8 0 0 0 .8 17h16.4a.8.8 0 0 0 .691-1.2l-8.2-14.118a.799.799 0 0 0-1.382 0z" fill="#DB0011"></path>
    <g fill="#fff">
      <path d="M8.1 5h1.8v6H8.1z"></path>
      <circle cx="9" cy="13.576" r="1.25"></circle>
    </g>
  </svg>
)

export default React.memo(NotificationErrorFilled)
