
import { DEFAULT_LOCALE, DEFAULT_COUNTRY, DEFAULT_GROUP_MEMBER, DEFAULT_GLOBAL_CHANNEL, DEFAULT_GBGF, DEFAULT_CLIENT_ID, DEFAULT_CORRELATION } from '@sgp/data/defaults';
export const getHeaders = () => {
  let defaultHeaders =  {
    'Content-Type': 'application/json',
    'X-HSBC-Chnl-CountryCode': DEFAULT_COUNTRY,
    'X-HSBC-Chnl-Group-Member': DEFAULT_GROUP_MEMBER,
    'X-HSBC-Global-Channel-Id': DEFAULT_GLOBAL_CHANNEL,
    'X-HSBC-Request-Correlation-Id': DEFAULT_CORRELATION,
    'Accept-Language': DEFAULT_LOCALE,
    'X-HSBC-Source-System-Id': 'DP',
    'Token_Type':'SESSION_TOKEN',
    'X-HSBC-Channel-Id': 'WEB',
    'X-HSBC-Locale': 'en_GB'
  };
  
   if (APP_ENV === 'DIT'){
    defaultHeaders = {...defaultHeaders , 'x-hsbc-e2e-trust-token': localStorage.getItem('advisor')}
   } 

  return defaultHeaders;
};

export default getHeaders;
