// third party libs
import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

//piggybank
import { Row, Cell } from '@piggybank/core/components/Grid';
import IconDashboard from '@piggybank/core/components/Icon/wrapped-icons/IconDashboard';
import IconUserMultiple from '@piggybank/core/components/Icon/wrapped-icons/IconUserMultiple';
import IconDocumentArticle from '@piggybank/core/components/Icon/wrapped-icons/IconDocumentArticle';
import IconReward from '@piggybank/core/components/Icon/wrapped-icons/IconReward';
import IconEducationBook from '@piggybank/core/components/Icon/wrapped-icons/IconEducationBook';
import IconNewspaper from '@piggybank/core/components/Icon/wrapped-icons/IconNewspaper';
import IconContactPhone from '@piggybank/core/components/Icon/wrapped-icons/IconContactPhone';
import IconDollarBill from '@piggybank/core/components/Icon/wrapped-icons/IconDollarBill';

// mws common ui
import withNLS from '@shared/HOC/withNLS';

//styles and NLS
import styles from './styles.pcss';
import nls from './nls/strings_nls';

// local components
import OverviewFilled from '@sgp-common/components/Page/Icon/icons/OverviewFilled';
import CustomersFilled from '@sgp-common/components/Page/Icon/icons/CustomersFilled';
import ApplicationsFilled from '@sgp-common/components/Page/Icon/icons/ApplicationsFilled';
import CampaignFilled from '@sgp-common/components/Page/Icon/icons/CampaignFilled';
import LibraryFilled from '@sgp-common/components/Page/Icon/icons/LibraryFilled';
import NewsFilled from '@sgp-common/components/Page/Icon/icons/NewsFilled';
import ContactFilled from '@sgp-common/components/Page/Icon/icons/ContactFilled';
import Equote from '@sgp-common/components/Left/Equote';

const Left = memo(({ NLS, sidebar, toggleSidebar }) => {
  let location = useLocation();
  const leftRef = useRef(null);
  const options = [
    {
      link: 'overview',
      className: 'OverviewIcon',
      activeIcon: <OverviewFilled/>,
      inactiveIcon: <IconDashboard width="1.5rem" />,
      text: NLS`overview`
    },
    {
      link: 'customers',
      className: 'CustomersIcon',
      activeIcon: <CustomersFilled/>,
      inactiveIcon: <IconUserMultiple width="1.5rem" />,
      text: NLS`customers`
    },
    {
      link: 'applications',
      className: 'CustomersIcon',
      activeIcon: <ApplicationsFilled/>,
      inactiveIcon: <IconDocumentArticle width="1.5rem" />,
      text: NLS`applications`
    },
    {
      link: 'campaigns',
      className: 'CampaingsIcon',
      activeIcon: <CampaignFilled/>,
      inactiveIcon: <IconReward width="1.5rem" />,
      text: NLS`campaings`
    },
    {
      link: 'library',
      className: 'LibraryIcon',
      activeIcon: <LibraryFilled/>,
      inactiveIcon: <IconEducationBook width="1.5rem" />,
      text: NLS`library`
    },
    {
      link: 'news',
      className: 'NewsIcon',
      activeIcon: <NewsFilled/>,
      inactiveIcon: <IconNewspaper width="1.5rem" />,
      text: NLS`news`
    },
    {
      link: 'contacts',
      className: 'ContactPhoneIcon',
      activeIcon: <ContactFilled/>,
      inactiveIcon: <IconContactPhone width="1.5rem" />,
      text: NLS`contacts`
    }
  ]

  useEffect(() => {
    document.addEventListener('click', handleLeftMenuOutSideClick);
    return () => {
      document.removeEventListener('click', handleLeftMenuOutSideClick);
    };
  }, [sidebar]);

  const handleLeftMenuOutSideClick = e => {
    if (leftRef?.current?.contains(e.target))
      return;
    else
      sidebar && toggleSidebar(false);
  };

  return (
    <div onClick={() => toggleSidebar(false)} className={sidebar ? cn(styles.Left) : cn(styles.DefaultLeft)}>
      <Row>
        <Cell>
          <ul ref={leftRef}>
            {
              options.map((option, index) => <li key={index}>
                                              <NavLink 
                                                to={`/${option?.link}`} 
                                                activeClassName={cn(styles.Active)}
                                              >
                                                <div className={cn(styles.setIcon)}>
                                                  <span className={styles[`${option?.className}`]}>
                                                  {
                                                    location.pathname === `/${option?.link}` 
                                                    ? <span className={styles.FilledIcon}>{option?.activeIcon}</span>
                                                    : option?.inactiveIcon
                                                  }
                                                  </span> 
                                                  <span className={styles.setIconText}>{option?.text}</span>
                                                </div>
                                              </NavLink>
                                            </li>
                          )
            }
            
            <span className={styles.bottomList}>
            <Equote 
              setIcon={cn(styles.setIcon)} 
              NewModalIcon={styles.NewModalIcon}
              setIconText={styles.setIconText}
              text={NLS`eQuote`}
            />
            <li>
              <a href="https://www.insurance.hsbc.com.sg/claims/" target="_blank">
                <div className={cn(styles.setIcon)}>                  
                  <span className={styles.DollarBillIcon}><IconDollarBill width="1.5rem" /></span>
                  <span className={styles.setIconText}>{NLS`eClaim`}</span>
                </div>
              </a>
            </li>
            </span>
          </ul>
        </Cell>
      </Row>   
    </div>
  );
});

Left.propTypes = {
  toggleSidebar: PropTypes.func,
  sidebar: PropTypes.bool,
};

export default withNLS(nls)(Left);
