import React from 'react';
const ChevronDown = props => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 14 7" className="pb-core-components-Icon-icon--Icon" role="img" focusable="false" aria-hidden="true">
    <defs>
      <polygon id="path-3" points="0 0 14 0 7 7"></polygon>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="My-Applications-(Desktop)" transform="translate(-321.000000, -241.000000)">
        <g id="Content" transform="translate(140.000000, 82.000000)">
          <g id="Table" transform="translate(20.000000, 140.000000)">
            <g id="Customer-table-row-">
              <g id="header">
                <g id="Table">
                  <g id="Table-header-">
                    <g>
                      <g>
                        <g id="Customer-table-row-">
                          <g>
                            <g>
                              <g id="Tables-Display/Size:S6/Header-Example/RScale:2">
                                <g id="System-icons/-Filter-down-/" transform="translate(161.000000, 19.000000)">
                                  <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-3"></use>
                                  </mask>
                                  <use id="New" fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-3"></use>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default React.memo(ChevronDown);
